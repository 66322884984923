import APIService from './APIService';

export default {
  getDadosGeograficos(codigosMunicipiosIbge) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().post('/municipios/get-dados-geograficos', JSON.stringify({ codigos_municipios_ibge: codigosMunicipiosIbge }))
        .then(response => resolve(response.data))
        .catch(() => reject(false));
    });
  },
  getMunicipios(regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`municipios/get-municipios?regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(() => reject(false));
    });
  },
  getRegionais() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`municipios/get-regionais`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
}