import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export function generateMonthlyDateRangeArray(
  startDate, 
  endDate,
  inputDateFormat = 'MM/YYYY',
  outputDateFormat = 'MM/YYYY'
) {
  startDate = dayjs(startDate, inputDateFormat).format("YYYY-MM"); 
  endDate   = dayjs(endDate, inputDateFormat).format("YYYY-MM");

  let start     = startDate.split('-');
  let end       = endDate.split('-');
  let startYear = parseInt(start[0]);
  let endYear   = parseInt(end[0]);
  let dates     = [];

  for (let i = startYear; i <= endYear; i++) {
    let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    let startMon = i === startYear ? parseInt(start[1])-1 : 0;

    for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      let month = j+1;
      let displayMonth = month < 10 ? '0'+month : month;

      let date =  dayjs([i, displayMonth].join('-')).format(outputDateFormat);

      dates.push(date);
    }
  }

  return dates;
}