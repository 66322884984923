import APIService from './APIService';

export default {
  getDadosIndicadoresTodasEmpresas(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-indicadores-todas-empresas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosKpisTotalizadosVisaoAneel(competenciaDe, competenciaAte, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-kpis-totalizados-visao-aneel?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosSituacaoSolicitacoes(competenciaDe, competenciaAte, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-situacao-solicitacoes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCardPendentes(competenciaDe, competenciaAte, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-card-pendentes?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCardConcluidas(competenciaDe, competenciaAte, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-card-concluidas?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosCardsKpisPorCompetencia(competenciaDe, competenciaAte, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-cards-kpis-por-competencia?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosKpisTotalizadosVisaoMunicipios(competencia, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-kpis-totalizados-visao-municipios?competencia=${competencia}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  importarMetasKpis(tipoCadastro, arquivoId) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/importar-metas-kpis?tipoCadastro=${tipoCadastro}&arquivoId=${arquivoId}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  baixarMetasKpis(tipoCadastro, config) {
    return APIService.apiCall().get(`dashboard-servicos/baixar-metas-kpis?tipoCadastro=${tipoCadastro}`, {
      responseType: 'blob',  
      ...config
    });
  },
  getMetasKpisGeral(competenciaDe, competenciaAte, servico) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-metas-kpis-geral?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&servico=${servico}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  },
  getMetasKpisMunicipios(competencia, servico,regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-metas-kpis-municipios?competencia=${competencia}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getDadosKpisGeraisVisaoMunicipiosCards(competencia, servico, regional) {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`dashboard-servicos/get-dados-kpis-gerais-visao-municipios-regionais?competencia=${competencia}&servico=${servico}&regional=${regional}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
}